//*******************************************************************************************************************
//File Name          :   DOIMaintenanceGrid.js
//Function           :   Disposition Of Inventory table/grid
//Created By         :   
//Created on         :   
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//31-Jan-2025    CV          (HDT#:778891)   Added devextreme text components and their corresponding styles.
//*******************************************************************************************************************

import React, {useState, useCallback, useRef, useMemo} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	Pager,
	Scrolling,
	Toolbar,
	ColumnFixing,
	Item,
	Export,
	SearchPanel,
	RequiredRule,
	Sorting,
	AsyncRule
} from "devextreme-react/data-grid";
import {getPermission, setMode, getMode} from "../configuration/helpers/globalFunctions";
import {Workbook} from "exceljs";
import Button from "devextreme-react/button";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import {alert, confirm} from "devextreme/ui/dialog";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import FileUploader from "devextreme-react/file-uploader";
import IconFilter from "../../components/filter.svg";
import { useAuth } from "../../contexts/auth";
let sspExists = false;

export default ({
	customDataSource,
	reportOptionSelected,
	controlNo,
	disableAdd,
	stateUp,
	clearControlNo
}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	let finalMsg = "";
	let canceled = false;
	const dataGrid = useRef(null);
	const {user} = useAuth();

	let token = user.data.token;
	const language = user.data.userData.language;
	const permission = getPermission(user, "DOI", "DOIMaintenance");
	const today = new Date();
	const month = today.getMonth() + 1; //months from 1-12
	const day = today.getDate();
	const year = today.getUTCFullYear();
	let dateFormatted = "";
	if (language === "ES") {
		dateFormatted = `${day}/${month}/${year}`;
	} else {
		dateFormatted = `${month}/${day}/${year}`;
	}

	let hours = today.getHours();
	let minutes = today.getMinutes();
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? "0" + minutes : minutes;
	const fullTime = hours + ":" + minutes;

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});

	const onExporting = (e) => {
		/* Get today Date First */

		const buildTitle = `DOIMaintenance_${dateFormatted}.xlsx`;

		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Report");

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 6, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"}
					};
				}
			}
		}).then((cellRange) => {
			/* Header */
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 1, 2, 8);

			headerRow.getCell(1).value = "DOI Maintenance";
			headerRow.getCell(1).font = {name: "Segoe UI Light", size: 20};
			headerRow.getCell(1).alignment = {horizontal: "center"};

			const detailsRow = worksheet.getRow(4);
			detailsRow.getCell(2).value = "Date: " + dateFormatted;
			detailsRow.getCell(3).value = "Time: " + fullTime;
			// Footer
			const footerRowIndex = cellRange.to.row + 2;
			const footerRow = worksheet.getRow(footerRowIndex);
			worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

			footerRow.getCell(1).value = "END OF REPORT";
			footerRow.getCell(1).font = {
				color: {argb: "BFBFBF"},
				italic: true,
				size: 16
			};
			footerRow.getCell(1).alignment = {horizontal: "center"};

			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};

	const dataGridConfiguration = useMemo(() => {
		return {
			id: "xrefMainDataGrid"
		};
	}, []);
	async function getHarnessDetail(value){
		let customURL = `${appInfo.doiURL}DOIMaintenance/CheckHarnessSerialNo?controlNo=${controlNo}&harnessSerialNo=${value}&option=${reportOptionSelected}`;
		return fetch(customURL, {
			method: "get",
			headers: {Authorization: `Bearer ${token}`}
		})
		.then((res) => res.json())
	}
	async function setCellValue(newData, value) {
		return getHarnessDetail(value)
		.then((res) => {
			if (res.isValid) {
				let itemID = "";
				if (sessionStorage.getItem("tempID") === null) {
					sessionStorage.setItem("tempID", 1);
					itemID = 1;
				} else {
					let currentID = parseInt(sessionStorage.getItem("tempID"));
					currentID = currentID + 1;
					sessionStorage.setItem("tempID", currentID);
					itemID = currentID;
				}

				let row = res.data[0];
				newData.Id = itemID;
				newData.harnessSerialNo = value;
				newData.sewsPartNo = row.sewsPartNo;
				newData.designChange = row.designChange;
				newData.shortPartNo = row.shortPartNo;
				newData.controlNo = row.controlNo;
				newData.option = row.option;
			} else {
				newData.harnessSerialNo = value;
				newData.sewsPartNo = null;
				newData.designChange = null;
				newData.shortPartNo = null;
				newData.controlNo = null;
				newData.option = null;
			}
		});	
	}
	function asyncValidation (cellParams){
		let customURL = `${appInfo.doiURL}DOIMaintenance/CheckHarnessSerialNo?controlNo=${controlNo}&harnessSerialNo=${cellParams.value}&option=${reportOptionSelected}`;
		return new Promise((resolve, reject) => {
			fetch(customURL, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((response) => response.json())
				.then((result) => {
					resolve({
						isValid: result.isValid,
						message: result.message
					});
				});
		});
	};

	const onEditorPreparing = (e) => {
		if (
			(e.dataField === "sewsPartNo" ||
				e.dataField === "designChange" ||
				e.dataField === "shortPartNo") &&
			e.parentType === "dataRow"
		) {
			e.editorOptions.disabled = e.row.isNewRow;
		}
	};

	const onEditCanceled = (e) => {
		setMode("View");
	};

	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	async function sendBatchRequest(changes) {
		await fetch(`${appInfo.doiURL}DOIMaintenance/`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json"
			},
			method: "post",
			body: JSON.stringify(changes)
		})
			.then((res) => res.json())
			.then((res) => {
				sessionStorage.removeItem("tempID");
				finalMsg = res.message.replace(/,/g, "<br/>");
				if (finalMsg !== "") {
					alert(finalMsg);
				}
			});
	}
	async function processBatchRequest(changes, component) {
		await sendBatchRequest(changes);
		await component.refresh(true);
		component.cancelEditData();
	}

	const onSaving = React.useCallback((e) => {
		let changesArray = e.changes.map((row) => {
			return row.data;
		});
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			finalMsg = "";
			if (e.changes.length && response) {
				e.promise = processBatchRequest(changesArray, e.component);
			}
			e.cancel = !response;
		});
	}, []);

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		//update mode label with js when first editing.changes event fired
		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);
	const onSaved = ()=>{
		setMode("View");
	}

	if (!permission.read) {
		return <Redirect to={"/home"} />;
	}

	const onInitNewRow = (e) => {
		stateUp(true);
	};
	
	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "DOIMaintenanceGridContainer"
				}}
				editorOptions={dataGridConfiguration}
				ref={dataGrid}
				dataSource={customDataSource}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				remoteOperations={true}
				onEditorPreparing={onEditorPreparing}
				onEditCanceled={onEditCanceled}
				onExporting={onExporting}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}
				onInitNewRow={onInitNewRow}>
				<Toolbar>
					<Item location='before' cssClass="item-select-file-button">
						<FileUploader
							disabled={disableAdd}
							showFileList={false}
							allowedFileExtensions={[".xls", ".xlsx"]}
							uploadMode='instantly'
							multiple={false}
							uploadHeaders={{Authorization: `Bearer ${token}`}}
							uploadUrl={
								appInfo.doiURL +
								`DOIMaintenance/Import?controlNo=${controlNo}&option=${reportOptionSelected}&exportDate=${dateFormatted}&exportTime=${fullTime}`
							}
							onUploaded={function (e) {
								let finalMsg = JSON.parse(e.request.responseText).message.replace(/,/g, "<br/>");
								if (finalMsg !== "") {
									dataGrid.current.instance.refresh();
									alert(finalMsg);
								}
								clearControlNo(true);
							}}
							onUploadError={function (e) {
								let finalMsg = JSON.parse(e.request.responseText).message.replace(/,/g, "<br/>");
								if (finalMsg !== "") {
									alert(finalMsg);
								}
							}}
							className="dx-fileuploader-content-doi-maintenance"
						/>
					</Item>
					<Item location='before' cssClass="item-hint-text">
						<div className='doi-hint-container'>
							<span className="doi-hint">*File should ONLY contain a column with the serial numbers to upload.</span>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: <label id='modeLabel'>View</label></span>
						</div>
					</Item>
					<Item location='after'>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFilters}
						/>
					</Item>
					<Item location="after" locateInMenu="never" name='addRowButton' disabled={disableAdd} />
					<Item location="after" locateInMenu="never" name='revertButton' />
					<Item location="after" locateInMenu="never" name='saveButton' />
				</Toolbar>
				<ColumnFixing enabled={true} />
				<Sorting mode='multiple' />
				<SearchPanel visible={true} width={240} placeholder='Search...' />
				<Editing
					mode='batch'
					allowUpdating={permission.update}
					allowAdding={permission.create}
					allowDeleting={permission.delete}
					selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
					startEditAction={dataGridSettings.startEditAction}
					texts={{saveAllChanges: "", cancelAllChanges: ""}}
				/>
				<Export enabled={true} />
				<Column dataField='Id' caption='No' cssClass="bigger-space" visible={false} />
				<Column
					dataField='harnessSerialNo'
					caption='Harness SNo'
					cssClass="bigger-space"
					editorOptions={{maxLength: 15}}
					setCellValue={setCellValue}
					allowSorting={false}>
					<AsyncRule validationCallback={asyncValidation} />
					<RequiredRule />
				</Column>
				<Column
					dataField='sewsPartNo'
					caption='SEWS Part No.'
					cssClass="bigger-space"
					disabled={true}
					allowSorting={false}
				/>
				<Column
					dataField='designChange'
					caption='Design Change'
					cssClass="bigger-space"
					disabled={true}
					allowSorting={false}
				/>
				<Column
					dataField='shortPartNo'
					caption='Short Part No.'
					cssClass="bigger-space"
					disabled={true}
					allowSorting={false}
				/>
				<Column dataField='controlNo'
					cssClass="bigger-space" 
					caption='ControlNo' 
					visible={false} 
					disabled={true} />
				<Column dataField='option' 
					cssClass="bigger-space" 
					caption='Option' 
					visible={false} 
					disabled={true} />
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};